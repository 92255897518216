@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 0 !important;
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none !important;
}

* {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

#shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}